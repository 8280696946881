import { Application } from '@hotwired/stimulus'
import { definitions } from 'stimulus:.'
import { definitions as componentDefinitions } from 'stimulus:../../components'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
application.stimulusUseDebug = application.debug
window.Stimulus = application

application.load(definitions)
application.load(componentDefinitions)
