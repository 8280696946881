import { Controller } from '@hotwired/stimulus'
import { Notifier } from '@airbrake/browser'

let airbrake = null

export default class extends Controller {
  static values = { configUrl: String }

  initialize () {
    this.load().then(this.configure)
  }

  configure (json) {
    if (json.enabled) {
      if (airbrake == null) {
        airbrake = new Notifier(json)

        airbrake.addFilter((notice) => {
          if (notice.url?.includes('chrome-extension://')) return null
          if (notice.errors?.length > 0) {
            if (notice.errors[0].message === 'Server responded with status 500') return null
            if (notice.errors[0].message.includes('NetworkError when attempting to fetch resource')) return null
            if (notice.errors[0].message.includes('The response (200) did not contain the expected <turbo-frame')) return null
            if (notice.errors[0].backtrace.some((frame) => frame.file.includes('node_modules/leaflet/'))) return null
          }

          return notice
        })
      }
    }
  }

  load () {
    return fetch(this.configUrlValue,
      {
        mode: 'cors',
        cache: 'default',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
  }
}
