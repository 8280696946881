import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']
  static values = {
    timeout: { type: Number, default: 2 }
  }

  connect () {
    if (this.timeoutValue > 0) {
      this.timeout = setTimeout(() => {
        this.close()
      }, this.timeoutValue * 1000)
    }
  }

  close () {
    this.containerTarget.classList.remove('fade')
    this.containerTarget.classList.add('fade-out')
    setTimeout(() => {
      this.containerTarget.remove()
    }, 300)
  }
}
