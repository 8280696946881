import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { BaseChartController, defaultTextStyle } from './base_chart_component_controller'
import { XYChartController } from './xy_chart_component_controller'

export default class extends BaseChartController {
  seriesType () {
    return 'sankey'
  }

  seriesToSeries (series, options) {
    return series.map((s) => {
      return merge(cloneDeep(XYChartController.defaultSeriesStyle), cloneDeep(options), s)
    })
  }

  options () {
    return {
      textStyle: cloneDeep(defaultTextStyle),
      color: this.colors,
      tooltip: this.tooltipOptions(),
      series: this.seriesToSeries(this.seriesValue, {
        type: this.seriesType(),
        left: '8%',
        right: '20%',
        bottom: '10%',
        nodeGap: 200,
        draggable: false,
        emphasis: {
          focus: 'adjacency'
        },
        lineStyle: {
          color: 'source',
          curveness: 0.5
        },
        label: {
          position: 'left',
          formatter: (data) => this.labelFormatter(this.localeValue, data),
          rich: {
            value: {
              fontWeight: 'bold'
            },
            name: {
              padding: [0, 0, 5, 0]
            }
          }
        }
      }),
      animation: false
    }
  }

  tooltipOptions () {
    return {
      trigger: 'item',
      position: 'bottom'
    }
  }

  labelFormatter (locale, { data: { style }, name, value }) {
    value = value.toLocaleString(locale, { maximumFractionDigits: 1 })
    const valueString = `{value|${value}/10}\n{name|${name}}`

    if (style !== undefined) { return `${valueString}\n{${style}| }` }
    return valueString
  }
}
