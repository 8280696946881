import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { BaseChartController, colors } from './base_chart_component_controller'

export default class extends BaseChartController {
  static values = merge(cloneDeep(BaseChartController.values), {
    split: { type: Number, default: 10 },
    max: { type: Number, default: 10 },
    value: Number
  })

  seriesType () {
    return 'gauge'
  }

  options () {
    return {
      textStyle: cloneDeep(BaseChartController.defaultTextStyle),
      series: [
        {
          type: 'gauge',
          startAngle: 210,
          endAngle: -30,
          center: ['50%', '60%'],
          radius: '100%',
          max: this.maxValue,
          splitNumber: this.splitValue,
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            width: '6%',
            itemStyle: {
              color: colors.secondary
            }
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              color: [
                [0.2, colors['score-reverse'][1]],
                [0.4, colors['score-reverse'][2]],
                [0.6, colors['score-reverse'][3]],
                [0.8, colors['score-reverse'][4]],
                [1, colors['score-reverse'][5]]
              ],
              width: 20
            }
          },
          axisTick: {
            splitNumber: 1,
            lineStyle: {
              width: 2,
              color: colors.gray.inactive
            }
          },
          splitLine: {
            lineStyle: {
              width: 2,
              color: colors.gray.inactive
            }
          },
          axisLabel: {
            show: false
          },
          detail: {
            width: '60%',
            offsetCenter: [0, '50%'],
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}{unit|/10}'
            },
            rich: {
              value: {
                fontSize: 40,
                color: colors.secondary,
                padding: [0, 0, 6, 0]
              },
              unit: {
                fontSize: 30,
                color: colors.gray.inactive
              }
            }
          },
          data: [
            {
              value: this.valueValue
            }
          ]
        }
      ],
      animation: this.pdfValue
    }
  }
}
