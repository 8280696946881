import { Controller } from '@hotwired/stimulus'
import * as echarts from 'echarts/core'
import { DatasetComponent, GridComponent, LegendComponent, TooltipComponent, MarkAreaComponent } from 'echarts/components'
import { BarChart, GaugeChart, LineChart, SankeyChart, ScatterChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import cloneDeep from 'lodash/cloneDeep'

import 'echarts/i18n/langDE.js'
import colors from '@quis-de/tailwindcss-config/src/colors'
import MorphReconnect from '../../javascript/mixins/morph_reconnect'

export { colors }

echarts.use([
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  MarkAreaComponent,
  LineChart,
  BarChart,
  ScatterChart,
  GaugeChart,
  SankeyChart,
  CanvasRenderer
])

export class BaseChartController extends MorphReconnect(Controller) {
  static values = {
    title: { type: String, default: '' },
    pdf: { type: Boolean, default: false },
    customColors: { type: Boolean, default: false },
    series: Array,
    locale: { type: String, default: 'DE' }
  }

  colors = this.customColorsValue ? [colors.purple[5], colors.purple[2]] : Object.values(colors.chart)

  connect () {
    this.chart = echarts.init(this.element, null, {
      locale: this.localeValue
    })
    this.chart.setOption(this.options())
    this.chart.on('click', this.handleClick)

    this.bindResize = this.resize.bind(this)
    window.addEventListener('resize', this.bindResize)
  }

  disconnect () {
    window.removeEventListener('resize', this.bindResize)
    this.chart.dispose()
  }

  resize () {
    if (this.chart !== null && this.chart !== undefined) {
      this.chart.resize()
    }
  }

  options () {
    return {
      title: this.titleOptions(),
      textStyle: cloneDeep(defaultTextStyle),
      color: this.colors,
      animation: !this.pdfValue
    }
  }

  titleOptions () {
    return {
      show: this.hasTitleValue,
      text: this.titleValue,
      textStyle: defaultTextStyle
    }
  }

  handleClick (params) {
    if (params.data.href !== undefined) {
      // eslint-disable-next-line no-undef
      Turbo.visit(params.data.href)
    }
  }
}

export const defaultTextStyle = {
  color: colors.gray.active,
  fontSize: 14,
  fontFamily: 'Lato, sans-serif'
}

export const defaultBoldTextStyle = {
  ...defaultTextStyle,
  fontWeight: 'bold',
  fontSize: 16
}

export default {
  BaseChartController,
  colors,
  defaultTextStyle,
  defaultBoldTextStyle
}
