import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { XYChartController } from './xy_chart_component_controller'

export default class extends XYChartController {
  static values = merge(cloneDeep(XYChartController.values), {
    barSeries: Array,
    lineSeries: Array
  })

  options () {
    return merge(super.options(), {
      dataset: this.transformToDataset(this.barSeriesValue).concat(this.transformToDataset(this.lineSeriesValue)),
      series: this.transformToSeries(this.barSeriesValue, {
        type: 'bar'
      }).concat(this.transformToSeries(this.lineSeriesValue, {
        type: 'line'
      }, this.barSeriesValue.length))
    })
  }
}
