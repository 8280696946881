import StimulusTomSelect from 'stimulus-tom-select'
import MorphReconnect from '../mixins/morph_reconnect'

// Connects to data-controller="select"
export default class extends MorphReconnect(StimulusTomSelect) {
  static values = {
    i18nCreate: { type: String, default: 'Add <strong>$input</strong>' },
    i18nNoResults: String,
    enableColumns: { type: Boolean, default: true },
    create: { type: Boolean, default: null }
  }

  initTomSelect () {
    const multiple = !!this.element.attributes.multiple
    const options = []
    if (this.enableColumnsValue && this.element.querySelectorAll('optgroup').length > 0) { options.push('optgroup_columns') }

    if (multiple) {
      options.push('remove_button')
    } else {
      options.push('no_backspace_delete')
    }

    this.options = {
      plugins: options,
      create: this.create(),
      persist: false,
      itemClass: multiple ? 'badge badge-secondary h-6 gap-x-1' : '',
      onItemAdd: function () {
        this.setTextboxValue('')
        this.refreshOptions()
      },
      render: {
        option_create: (data, escape) => {
          return `<div class="create">${this.i18nCreateValue.replace('$input', `<strong>${escape(data.input)}</strong>&hellip;`)}</div>`
        },
        no_results: (data, escape) => {
          return `<div class="no-results">${this.i18nNoResultsValue.replace('$input', `<strong>${escape(data.input)}</strong>&hellip;`)}</div>`
        }
      }
    }

    super.initTomSelect()
  }

  create () {
    if (this.createValue !== null) {
      return this.createValue
    } else {
      return !!this.element.attributes.multiple
    }
  }
}
