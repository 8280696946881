import { Controller } from '@hotwired/stimulus'
import { defaultOptions, passwordStrength } from 'check-password-strength'

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'tooltip', 'progress', 'submit']

  static values = {
    timeout: { type: Number, default: 2 },
    options: { type: Array, default: defaultOptions }
  }

  check () {
    const password = this.passwordTarget.value
    const passwordConfirmation = this.passwordConfirmationTarget.value
    const strength = passwordStrength(password, this.optionsValue)

    if (this.progressTarget) this.updateProgress(strength)
    if (this.tooltipTarget) this.updateTooltip(strength, password === passwordConfirmation)
    if (this.submitTarget) this.updateSubmit(strength, password === passwordConfirmation)
  }

  updateProgress (strength) {
    const classList = ['progress']
    this.progressTarget.value = 0

    if (strength.length > 0) {
      this.progressTarget.value = (strength.id + 1) * (strength.id + 1)

      switch (strength.id) {
        case 0:
        case 1:
          classList.push('progress-error')
          break
        case 2:
          classList.push('progress-warning')
          break
        case 3:
          classList.push('progress-success')
          break
      }
    }
    this.progressTarget.classList = classList.join(' ')
  }

  updateTooltip (strength, confirmed) {
    const tooltipTarget = this.tooltipTarget

    let message = ''
    if (strength.length === 0) {
      message = 'Kein Passwort eingegeben.'
    } else {
      if (strength.length < 10) {
        message += 'Das Passwort sollte mindestens 10 Zeichen lang sein.\n'
      }
      if (!strength.contains.includes('lowercase')) {
        message += 'Das Passwort sollte mindestens einen Kleinbuchstaben enthalten.\n'
      }
      if (!strength.contains.includes('uppercase')) {
        message += 'Das Passwort sollte mindestens einen Großbuchstaben enthalten.\n'
      }
      if (!strength.contains.includes('number')) {
        message += 'Das Passwort sollte mindestens eine Ziffer enthalten.\n'
      }
      if (!strength.contains.includes('symbol')) {
        message += 'Das Passwort sollte mindestens ein Symbol enthalten.\n'
      }
      if (!confirmed) {
        message += 'Die Passwörter sind nicht identisch.\n'
      }
    }

    if (message === '') {
      message = 'Das Passwort sieht gut aus.'
    }
    tooltipTarget.setAttribute('data-tip', message)

    tooltipTarget.classList.add('tooltip-open')
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      tooltipTarget.classList.remove('tooltip-open')
    }, this.timeoutValue * 1000)
  }

  updateSubmit (strength, confirmed) {
    if (strength.value === 'Strong' && confirmed) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
