import AutoSubmit from '@stimulus-components/auto-submit'

export default class extends AutoSubmit {
  static targets = ['url']
  static values = {
    updateUrl: { type: Boolean, default: false }
  }

  connect () {
    super.connect()
  }

  save () {
    if (this.updateUrlValue) { this.updateHistory() }
    super.save()
  }

  updateHistory () {
    const url = new URL(this.element.action)

    this.urlTargets.forEach((elem) => {
      if (elem.type !== 'radio' || elem.checked) {
        url.searchParams.append(elem.name, elem.value)
      }
    })

    // eslint-disable-next-line no-undef
    history.replaceState({}, null, url.toString())
  }
}
