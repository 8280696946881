import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    disableFileUpload: { type: Boolean, default: false }
  }

  connect () {
    super.connect()
    if (this.disableFileUploadValue) {
      this.bindOnFileAccept = this.#onFileAccept.bind(this)
      this.element.addEventListener('trix-file-accept', this.bindOnFileAccept)
    }

    this.bindOnBeforeMorph = this.onBeforeMorph.bind(this)
    this.element.addEventListener('turbo:before-morph-attribute', this.bindOnBeforeMorph)
    this.element.addEventListener('turbo:before-morph-element', this.bindOnBeforeMorph)
  }

  disconnect () {
    this.element.removeEventListener('turbo:before-morph-element', this.bindOnBeforeMorph)
    this.element.removeEventListener('turbo:before-morph-attribute', this.bindOnBeforeMorph)

    if (this.disableFileUploadValue) {
      this.element.removeEventListener('trix-file-accept', this.bindOnFileAccept)
    }
    super.disconnect()
  }

  #onFileAccept (event) {
    event.preventDefault()
  }

  onBeforeMorph (event) {
    const { target } = event

    if (target.tagName === 'TRIX-EDITOR' || target.tagName === 'TRIX-TOOLBAR') {
      event.preventDefault()
    }
  }
}
