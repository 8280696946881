import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['from', 'to']
  static values = {
    outColor: { type: String, default: 'transparent' },
    inColor: { type: String, default: 'red' },
    distanceBetweenRanges: Number
  }

  connect () {
    super.connect()
    this.fillSlider()

    this.bindSetByFromRange = this.setByFromRange.bind(this)
    this.bindSetByToRange = this.setByToRange.bind(this)
    this.bindFillSlider = this.fillSlider.bind(this)

    this.fromTarget.addEventListener('input', this.bindSetByFromRange)
    this.toTarget.addEventListener('input', this.bindSetByToRange)
    window.addEventListener('resize', this.bindFillSlider)
  }

  disconnect () {
    window.removeEventListener('resize', this.bindFillSlider)

    this.toTarget.removeEventListener('input', this.bindSetByToRange)
    this.fromTarget.removeEventListener('input', this.bindSetByFromRange)

    super.disconnect()
  }

  setByFromRange () {
    const step = this.hasDistanceBetweenRangesValue ? this.distanceBetweenRangesValue : Number(this.fromTarget.step)

    if (this.fromTarget.valueAsNumber + step > this.toTarget.valueAsNumber) {
      this.fromTarget.value = this.toTarget.valueAsNumber - step
    }

    this.fillSlider()
  }

  setByToRange () {
    const step = this.hasDistanceBetweenRangesValue ? this.distanceBetweenRangesValue : Number(this.toTarget.step)

    if (this.toTarget.valueAsNumber - step < this.fromTarget.valueAsNumber) {
      this.toTarget.value = this.fromTarget.valueAsNumber + step
    }

    this.fillSlider()
  }

  fillSlider () {
    if (this.fromTarget.valueAsNumber > this.toTarget.valueAsNumber || this.toTarget.valueAsNumber < this.fromTarget.valueAsNumber) { return }

    const offset = 12
    const pixelPerStep = (this.toTarget.offsetWidth - offset * 2) / (this.toTarget.max - this.toTarget.min)
    const fromPosition = ((this.fromTarget.valueAsNumber - this.fromTarget.min) * (pixelPerStep)) + offset
    const toPosition = ((this.toTarget.valueAsNumber - this.toTarget.min) * (pixelPerStep)) + offset

    this.toTarget.style.background = `linear-gradient(
      to right,
      ${this.outColorValue} 0px,
      ${this.outColorValue} ${fromPosition}px,
      ${this.inColorValue} ${fromPosition}px,
      ${this.inColorValue} ${toPosition}px, 
      ${this.outColorValue} ${toPosition}px, 
      ${this.outColorValue} ${this.toTarget.offsetWidth}px)`
  }
}
