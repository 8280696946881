import { Controller } from '@hotwired/stimulus'
import MorphReconnect from '../../javascript/mixins/morph_reconnect'

export default class extends MorphReconnect(Controller) {
  static targets = ['tab', 'tabContent', 'tabMenu']

  connect () {
    super.connect()

    const selectedTab = this.tabTargets.filter((tabTarget) => { return tabTarget.checked })[0]
    if (selectedTab) {
      this.selectTab({ params: { tab: selectedTab.dataset['common-TabsComponentTabParam'] } })
    }
  }

  selectTab ({ params: { tab } }) {
    this.tabContentTargets.filter((tabContentTarget) => { return tabContentTarget.dataset['common-TabsComponentTabParam'] === tab })[0].checked = true
    if (this.tabMenuTargets.length > 0) {
      this.tabMenuTargets.filter((tabMenuTarget) => { return tabMenuTarget.dataset['common-TabsComponentTabParam'] === tab })[0].checked = true
    }

    this.dispatch('select', { detail: { tab } })
  }
}
