import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'hideable', 'reverseHideable']
  static values = {
    hideByType: String
  }

  connect () {
    const self = this
    self.sourceTarget.addEventListener('change', function () {
      self.change()
    }, false)
    self.change()
  }

  change () {
    const setHidden = !this.sourceTarget.value.startsWith(this.hideByTypeValue)
    this.hideableTargets.forEach((target) => {
      if (setHidden) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }
    })

    this.reverseHideableTargets.forEach((target) => {
      if (setHidden) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    })
  }
}
