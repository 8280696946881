import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['removeImage', 'imageInput', 'dropArea', 'preview', 'fallback']

  previewImage () {
    const [file] = this.imageInputTarget.files
    if (file) {
      if (file.type.match(/image\/.*/)) {
        if (this.hasRemoveImageTarget) {
          this.removeImageTarget.checked = false
        }
        this.previewTarget.src = URL.createObjectURL(file)
        this.previewTarget.classList.remove('hidden')
        this.fallbackTarget.classList.add('hidden')
      } else {
        this.removeImage()
      }
    }
  }

  removeImage () {
    if (this.hasRemoveImageTarget) {
      this.removeImageTarget.checked = true
    }
    this.imageInputTarget.value = ''
    this.fallbackTarget.classList.remove('hidden')
    this.previewTarget.classList.add('hidden')
  }

  acceptImage (event) {
    event.preventDefault()
    return true
  }

  dropImage (event) {
    event.preventDefault()
    if (event.dataTransfer.files.length === 0) return undefined
    const dT = new DataTransfer() // eslint-disable-line no-undef
    dT.items.add(event.dataTransfer.files[0])
    this.imageInputTarget.files = dT.files

    this.previewImage()
  }
}
