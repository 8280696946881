import { Controller } from '@hotwired/stimulus'

class ScrollTo extends Controller {
  static offset = 96
  static behavior = 'smooth'

  static scrollTo (hash) {
    const id = hash.replace(/^#/, '')
    const target = document.getElementById(id)

    if (!target) {
      console.warn(`[scroll-to] The element with the id: "${id}" does not exist on the page.`)
      return
    }

    const elementPosition = target.getBoundingClientRect().top + window.scrollY
    const offsetPosition = elementPosition - this.offset

    window.scrollTo({
      top: offsetPosition, behavior: this.behavior
    })
  }

  initialize () {
    this.scroll = this.scroll.bind(this)
  }

  connect () {
    this.element.addEventListener('click', this.scroll)
  }

  disconnect () {
    this.element.removeEventListener('click', this.scroll)
  }

  scroll (event) {
    event.preventDefault()

    window.history.pushState(null, null, this.element.hash)
    this.constructor.scrollTo(this.element.hash)
  }
}

// Scroll on page load even if Controller is not connected on the page
document.addEventListener('DOMContentLoaded', () => {
  if (window.location.hash) {
    ScrollTo.scrollTo(window.location.hash)
  }
})

export default ScrollTo
