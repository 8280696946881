import { Controller } from '@hotwired/stimulus'
import { numberToArea, numberToHuman } from '../helpers/number_helper'

export default class extends Controller {
  static targets = ['input', 'tooltip']

  static values = {
    min: { type: String, default: '' },
    max: { type: String, default: '' },
    formatter: { type: String, default: 'human' },
    locale: { type: String, default: 'DE' }
  }

  connect () {
    super.connect()
    this.updateTip()

    this.bindUpdateTip = this.updateTip.bind(this)
    this.bindShowTip = this.showTip.bind(this)
    this.bindHideTip = this.hideTip.bind(this)
    this.bindOpdateTipPosition = this.updateTipPosition.bind(this)

    this.inputTarget.addEventListener('mouseenter', this.bindShowTip)
    this.inputTarget.addEventListener('mouseleave', this.bindHideTip)

    this.inputTarget.addEventListener('focus', this.bindShowTip)
    this.inputTarget.addEventListener('blur', this.bindHideTip)

    this.inputTarget.addEventListener('input', this.bindUpdateTip)

    window.addEventListener('resize', this.bindOpdateTipPosition)
  }

  disconnect () {
    this.inputTarget.removeEventListener('input', this.bindUpdateTip)
    this.inputTarget.removeEventListener('blur', this.bindHideTip)
    this.inputTarget.removeEventListener('focus', this.bindShowTip)

    this.inputTarget.removeEventListener('mouseleave', this.bindHideTip)
    this.inputTarget.removeEventListener('mouseenter', this.bindShowTip)
    window.removeEventListener('resize', this.bindOpdateTipPosition)

    super.disconnect()
  }

  showTip () {
    this.tooltipTarget.classList.add('tooltip-open')
  }

  hideTip () {
    this.tooltipTarget.classList.remove('tooltip-open')
  }

  updateTip () {
    let value = this.inputTarget.valueAsNumber
    if (this.inputTarget.value === this.inputTarget.min && this.hasMinValue && this.minValue !== undefined && this.minValue !== '') {
      value = this.minValue
    } else if (this.inputTarget.value === this.inputTarget.max && this.hasMaxValue && this.maxValue !== undefined && this.maxValue !== '') {
      value = this.maxValue
    } else {
      switch (this.formatterValue) {
        case 'area':
          value = numberToArea(value, this.localeValue)
          break
        case 'human':
          value = numberToHuman(value, this.localeValue)
          break
      }
    }

    this.tooltipTarget.dataset.tip = value

    this.updateTipPosition()
  }

  updateTipPosition () {
    const offset = 12
    const pixelPerStep = (this.inputTarget.offsetWidth - offset * 2) / (this.inputTarget.max - this.inputTarget.min)
    const position = ((this.inputTarget.valueAsNumber - this.inputTarget.min) * (pixelPerStep)) + offset

    this.tooltipTarget.style.left = `${position}px`
  }
}
