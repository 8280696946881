import merge from 'lodash/merge'

import { XYChartController } from './xy_chart_component_controller'

export default class extends XYChartController {
  defaultSeriesStyle = {
    selectedMode: 'single',
    symbol: 'circle',
    symbolSize: 8,
    emphasis: {
      scale: 1.8
    }
  }

  seriesType () {
    return 'scatter'
  }

  tooltipFormatter () {
    return {
      turboFrame: (params) => this.turboFrameFormatter(params)
    }[this.tooltipFormatterValue.formatter]
  }

  turboFrameFormatter (params) {
    const entry = params.value
    return this.tooltipFormatterValue.blueprint.replaceAll(':id', entry.id)
  }

  tooltipOptions () {
    return merge(super.tooltipOptions(), {
      trigger: 'item',
      triggerOn: 'click',
      position: 'top',
      padding: 0
    })
  }
}
