import merge from 'lodash/merge'

import { XYChartController } from './xy_chart_component_controller'
import colors from '@quis-de/tailwindcss-config/src/colors'
import cloneDeep from 'lodash/cloneDeep'

export default class extends XYChartController {
  defaultSeriesStyle = {
    selectedMode: 'single',
    symbol: 'circle',
    symbolSize: 8
  }

  colors = [colors.secondary]

  transformToDataset (series) {
    return undefined
  }

  transformToSeries (series, options, offset = 0) {
    return series.map((s, index) => {
      const data = s.data.map((entry) => {
        return {
          name: entry.name,
          value: entry.value,
          href: entry.href,
          symbolSize: (entry.size * (s.maxSize - 10) + 10)
        }
      })
      return merge(cloneDeep(this.defaultSeriesStyle), cloneDeep(options), {
        name: s.title,
        data
      })
    })
  }

  options () {
    const superOptions = super.options()
    const max = Math.max(...superOptions.series[0].data.map((entry) => { return entry.value[1] }))

    return merge(superOptions, {
      grid: {
        left: 40,
        right: 40,
        top: max >= 0.85 ? 45 : 0,
        bottom: 40,
        containLabel: true
      }
    })
  }

  seriesType () {
    return 'scatter'
  }

  tooltipOptions () {
    return {
      position: 'top',
      formatter: '{b}'
    }
  }

  xAxisOptions () {
    return merge(super.xAxisOptions(), {
      type: 'value',
      scale: false,
      nameLocation: 'middle',
      nameTextStyle: {
        fontSize: 16,
        lineHeight: 50
      },
      minInterval: 0,
      min: -1,
      max: 1,
      axisLabel: {
        formatter: axisFormatter
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    })
  }

  yAxisOptions () {
    return merge(super.yAxisOptions(), {
      type: 'value',
      scale: false,
      nameLocation: 'middle',
      nameTextStyle: {
        fontSize: 16,
        lineHeight: 50
      },
      nameRotate: 270,
      minInterval: 0,
      min: -1,
      max: 1,
      axisLabel: {
        rotate: 270,
        formatter: axisFormatter
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    })
  }
}

function axisFormatter (value, _index) {
  if (value === 0.5) {
    return 'Hoch'
  } else if (value === -0.5) {
    return 'Niedrig'
  } else {
    return ''
  }
}
