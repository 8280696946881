import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String,
    maxItems: Number,
    timeout: { type: Number, default: 10000 }
  }

  static targets = ['carousel', 'prevButton', 'nextButton']

  connect () {
    this.currentId = 0
    if (this.timeoutValue > 0) {
      this.stepTimer = setInterval(() => this.next(), this.timeoutValue)
    }
  }

  disconnect () {
    if (this.timeoutValue > 0) {
      clearInterval(this.stepTimer)
    }
  }

  next () {
    if (this.nextHiddenItemId() < this.maxItemsValue) {
      this.currentId = this.nextHiddenItemId()
    } else {
      this.currentId = 0
    }
    this.scrollTo(this.currentId)
  }

  prev () {
    if (this.prevHiddenItemId() >= 0) {
      this.currentId = this.prevHiddenItemId()
    } else {
      this.currentId = this.maxItemsValue - 1
    }
    this.scrollTo(this.currentId, 'prev')
  }

  isInvisible (id, direction = 'next') {
    const elem = document.getElementById(`${this.idValue}_${id}`)

    const left = elem.getClientRects()[0].left - this.carouselTarget.getClientRects()[0].left
    const right = this.carouselTarget.getClientRects()[0].right - elem.getClientRects()[0].right

    if (direction === 'next') {
      return right < 0
    } else {
      return left < 0
    }
  }

  nextHiddenItemId () {
    for (let i = this.currentId + 1; i < this.maxItemsValue; i++) {
      if (this.isInvisible(i)) {
        return Math.min(i, this.maxItemsValue)
      }
    }
    return 0
  }

  prevHiddenItemId () {
    for (let i = this.currentId - 1; i >= 0; i--) {
      if (this.isInvisible(i, 'prev')) {
        return Math.max(i, 0)
      }
    }
    return -1
  }

  scrollTo (id, direction = 'next') {
    const el = document.getElementById(`${this.idValue}_${id}`)
    let left
    if (direction === 'next') {
      left = (el.offsetLeft + el.clientWidth) - this.carouselTarget.clientWidth - this.carouselTarget.offsetLeft
    } else {
      left = el.offsetLeft - this.carouselTarget.offsetLeft
    }

    this.carouselTarget.scroll({ left })
  }
}
