import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'input']
  static values = {
    empty: { type: String, default: 'fa-search' },
    clear: { type: String, default: 'fa-xmark' }
  }

  connect () {
    this.bindInput = this.input.bind(this)
    this.bindClearInput = this.clearInput.bind(this)

    this.inputTarget.addEventListener('input', this.bindInput)
    this.input()
  }

  disconnect () {
    this.inputTarget.removeEventListener('input', this.bindInput)
  }

  input () {
    const value = this.inputTarget.value

    if (value === '') {
      this.searchIcon()
    } else {
      this.clearIcon()
    }
  }

  searchIcon () {
    this.iconTarget.removeEventListener('click', this.bindClearInput)
    this.iconTarget.classList.remove(this.clearValue, 'hover:cursor-pointer')
    this.iconTarget.classList.add(this.emptyValue)
  }

  clearIcon () {
    this.iconTarget.classList.remove(this.emptyValue)
    this.iconTarget.classList.add(this.clearValue, 'hover:cursor-pointer')
    this.iconTarget.addEventListener('click', this.bindClearInput)
  }

  clearInput () {
    this.inputTarget.value = ''
    // eslint-disable-next-line no-undef
    this.inputTarget.dispatchEvent(new InputEvent('input'))
  }
}
