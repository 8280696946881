import NestedFormController from '@stimulus-components/rails-nested-form'

export default class extends NestedFormController {
  static values = {
    limit: {
      type: Number,
      default: 0
    },
    firstTypes: Array
  }

  connect () {
    super.connect()
    this.checkLimit()
  }

  add (event) {
    super.add(event)
    this.checkLimit()
    this.setType()
  }

  remove (event) {
    super.remove(event)
    this.checkLimit()
  }

  checkLimit () {
    if (!this.hasLimitValue || this.limitValue === 0) return null

    const visibleElements = Array.from(this.element.querySelectorAll(this.wrapperSelectorValue)).filter((elem) => elem.style.display !== 'none')
    if (visibleElements.length < this.limitValue) {
      this.targetTarget.style.display = null
    } else {
      this.targetTarget.style.display = 'none'
    }
  }

  setType () {
    if (!this.hasFirstTypesValue) return null
    const visibleElements = Array.from(this.element.querySelectorAll(this.wrapperSelectorValue))
      .filter((elem) => elem.style.display !== 'none')

    for (const firstType of this.firstTypesValue) {
      if (visibleElements.filter((elem) => elem.querySelector('[id$="type"]').value === firstType).length === 0) {
        visibleElements.pop().querySelector('[id$="type"]').value = firstType
      }
    }
  }
}
