import { Controller } from '@hotwired/stimulus'

import MorphReconnect from '../mixins/morph_reconnect'

let TURBO_REFRESH_LOCK = false

export default class extends MorphReconnect(Controller) {
  static timeout

  connect () {
    this.timeout = setInterval(this.reloadPage, 10000)

    this.bindLock = this.lock.bind(this)
    this.bindUnlock = this.unlock.bind(this)

    window.addEventListener('turbo:before-visit', this.bindLock)
    window.addEventListener('turbo:before-render', this.bindUnlock)
  }

  disconnect () {
    clearInterval(this.timeout)

    window.removeEventListener('turbo:before-visit', this.bindLock)
    window.removeEventListener('turbo:before-render', this.bindUnlock)
  }

  reloadPage () {
    if (TURBO_REFRESH_LOCK) return

    // eslint-disable-next-line no-undef
    Turbo.visit(window.location.href, { action: 'replace' })
  }

  lock () {
    TURBO_REFRESH_LOCK = true
  }

  unlock () {
    TURBO_REFRESH_LOCK = false
  }
}
