import Sortable from '@stimulus-components/sortable'

export default class extends Sortable {
  // You can override the `onUpdate` method here.
  onUpdate (event) {
    event.target.querySelectorAll('.nested-image').forEach((elem, pos) => {
      elem.querySelector('[id$=position]').value = pos
    })
  }

  get defaultOptions () {
    return {
      handle: '.handle',
      animation: 150
    }
  }
}
