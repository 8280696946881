import { Controller } from '@hotwired/stimulus'

import { saveAs } from 'file-saver'
import { toBlob } from 'html-to-image'

export default class extends Controller {
  static values = {
    filename: String,
    copyright: String,
    footerContainer: String,
    excludedClasses: { type: Array, default: [] }
  }

  take () {
    const footer = this.addFooter()
    toBlob(this.element, { filter: this.filterElements() }).then((blob) => {
      saveAs(blob, this.filenameValue)

      if (footer !== undefined) { footer.remove() }
    })
  }

  filterElements () {
    if (!this.hasExcludedClassesValue) { return undefined }
    if (this.excludedClassesValue.length === 0) { return undefined }
    const that = this
    return (node) => {
      if (node.classList) {
        return !that.excludedClassesValue.some((classname) => node.classList?.contains(classname))
      }
      return true
    }
  }

  addFooter () {
    if (!this.hasFooterContainerValue) { return undefined }

    const elem = this.element.getElementsByClassName(this.footerContainerValue)[0]
    const footer = document.createElement('div')
    footer.classList.add('flex', 'gap-1', 'justify-center', 'text-xs', 'mt-2', 'px-4')
    footer.innerHTML = `<div>${this.copyrightValue}</div>`
    elem.appendChild(footer)
    return footer
  }
}
