import ScatterChartController from '../../chart/scatter_chart_component_controller'

export default class extends ScatterChartController {
  connect () {
    super.connect()

    this.bindOnSelectChanged = this.onSelectChanged.bind(this)
    this.chart.on('selectchanged', this.bindOnSelectChanged)
  }

  disconnect () {
    this.chart.off('selectchanged', this.bindOnSelectChanged)
    super.disconnect()
  }

  onSelectChanged (params) {
    const seriesIndex = params.selected[0]?.seriesIndex

    if (seriesIndex == null) return

    const dataIndex = params.selected[0].dataIndex[0]
    const point = this.options().dataset[seriesIndex].source[dataIndex]

    this.dispatch('highlight', { detail: { id: point.id, coordinate: point.coordinate } })
  }

  highlightPointById (event) {
    const [seriesIndex, dataIndex] = this.findById(event.detail.id)
    this.chart.dispatchAction({
      type: 'select',
      seriesIndex,
      dataIndex
    })
    this.chart.dispatchAction({
      type: 'showTip',
      seriesIndex,
      dataIndex
    })
  }

  findById (id) {
    let seriesIndex, dataIndex
    this.options().dataset.some((series, index) => {
      seriesIndex = index
      dataIndex = series.source.findIndex(data => data.id === id)
      return dataIndex >= 0
    })

    if (dataIndex !== undefined) {
      return [seriesIndex, dataIndex]
    }
  }
}
