import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    changeColorOnClick: { type: Boolean, default: true }
  }

  connect () {
    this.originalContent = this.element.innerHTML
  }

  copy (event) {
    event.preventDefault()

    const text = this.element.attributes.value.textContent
    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.element.innerHTML = '<span class="icon mr-2"><i class="fas fa-check"></i></span>' + this.data.get('successContent')
    if (this.changeColorOnClickValue) {
      this.element.classList.add('btn-primary')
      this.element.classList.remove('btn-secondary')
    }
    this.timeout = setTimeout(() => {
      this.element.innerHTML = this.originalContent
      if (this.changeColorOnClickValue) {
        this.element.classList.add('btn-secondary')
        this.element.classList.remove('btn-primary')
      }
    }, 3000)
  }
}
