import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const elem = this.element

    this.element.querySelectorAll('a').forEach(function (link) {
      if (link.host !== window.location.host) {
        link.target = '_blank'
      } else if (elem.closest('turbo-frame')) {
        link.target = '_top'
      }
    })
  }
}
