import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static popup = undefined

  connect () {
    const self = this
    const elem = this.element
    elem.addEventListener('click', (e) => {
      e.preventDefault()
      const width = 600
      const height = 1050
      const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2)
      const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2)

      self.popup = window.open(elem.getAttribute('href'), 'Popup', `height=${height}, width=${width}, left=${left}, top=${top}, location=no, status=no, menubar=no`)
    })
  }
}
