import '@hotwired/turbo-rails'

import 'trix'
import '@rails/actiontext'
import * as ActiveStorage from '@rails/activestorage'
// require("channels")

// Stimulus
import './controllers'

/* global Trix */
Trix.config.blockAttributes.heading1.tagName = 'h4'

Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false
Trix.config.attachments.file.caption.size = false

ActiveStorage.start()
