import * as L from 'leaflet/dist/leaflet-src.esm'

export default (superclass) => class extends superclass {
  connect () {
    this.highlightableMarkers = {}
    this.highlightedMarkers = []
    this.addMainMarker()
    this.addAdditionalMarkers()
  }

  addMainMarker () {
    this.addMarker(this.coordinateValue, this.iconHtmlValue, this.dragableMarkerValue, false)
  }

  addAdditionalMarkers () {
    try { this.additionalMarkersValue } catch (e) { return } // eslint-disable-line no-unused-expressions

    for (const markerData of this.additionalMarkersValue) {
      this.addMarker(markerData.coordinate, markerData.icon, false, true, markerData)
    }
  }

  addMarker (coordinate, iconHtml, dragable, highlightable, extra) {
    const icon = L.divIcon({ html: iconHtml, className: 'pin-anchor', iconSize: [0, 0] })
    const marker = L.marker(coordinate, {
      icon,
      draggable: dragable,
      interactive: dragable || highlightable
    }).addTo(this.map)
    if (this.zoomToFitValue.includes('markers')) this.featureGroup.addLayer(marker)

    if (dragable) {
      marker.on('dragend', () => {
        const position = marker.getLatLng()
        this.coordinateTarget.value = [position.lat, position.lng].toString()
        this.formTarget.submit()
      })
    }

    if (highlightable) {
      this.highlightableMarkers[extra.id] = marker
      marker.on('click', () => {
        this.highlightMarker(marker, extra)
      })
    }
  }

  highlightMarker (marker, extra, dispatch = true) {
    this.highlightedMarkers.forEach((marker) => {
      const icon = marker.getIcon()
      icon.options.className = 'pin-anchor'
      marker.setIcon(icon)
    })
    this.highlightedMarkers = []
    if (marker === undefined) { return }

    const icon = marker.getIcon()
    icon.options.className = 'pin-anchor highlight'
    marker.setIcon(icon)

    if (dispatch) {
      this.dispatch('highlight', { detail: { id: extra.id, coordinate: extra.coordinate } })
    }

    this.highlightedMarkers.push(marker)
  }

  highlightMarkerById (event) {
    const marker = this.highlightableMarkers[event.detail.id]
    this.highlightMarker(marker, undefined, false)
  }
}
